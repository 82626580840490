<template>
  <div>
      <!-- table -->

      <div class="m-2">
          <b-row>

              <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem" >
                  <label>Show</label>
                  <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                      @input="filterTable"
                  />
                  <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="9" class="mobile_tab_max_width_flex">
                  <div class="d-flex align-items-center justify-content-end mobile-view mr_1_last_child mobile_tab_display_block">
                      <b-form-input
                          v-model="searchQuery"
                          class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                          placeholder="Search..."
                          @input="filterTable"
                          autoComplete="false"
                      />

                      <b-button
                          :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                          variant="success"
                          class="mr-1 mobile-margin-bottom"
                          @click="getReport('pdf')"
                          v-if="checkPermission('qm-qi', 'PDF') && tab_status == 'completed'"
                      >
                          <span class="text-nowrap">PDF</span>
                      </b-button>

                      <b-button
                          :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                          variant="secondary"
                          class="mr-1 mobile-margin-bottom"
                          @click="getReport('excel')"
                          v-if="checkPermission('qm-qi', 'Excel') && tab_status == 'completed'"
                      >
                          <span class="text-nowrap">Excel</span>
                      </b-button>

                      <b-button :disabled="!(selected.length > 0)" variant="success" class="mr-1 mobile-margin-bottom" @click="closeQI" v-if="tab_status == 'validate' && checkPermission('qm-qi', 'Close All')">
                          <span class="text-nowrap">Close All</span>
                      </b-button>

                      <b-button
                          :disabled="!(selected.length > 0)"
                          variant="danger"
                          class="mobile-margin-bottom"
                          @click="changeMultipleStatus('deleted')"
                          v-if="checkPermission('qm-qi', 'Delete')"
                      >
                          <span class="text-nowrap">Delete</span>
                      </b-button>

                      <b-button                
                          variant="primary"
                          class="ml-1 mobile-margin-bottom"
                          @click="zipDownload()"
                          :disabled="selected && selected.length > 0 ? false:true"
                          v-if="(checkPermission('qm-qi', 'Zip Download') && (tab_status == 'completed'))"
                          >
                          <span class="text-nowrap">Zip Download</span>
                      </b-button>

                      <b-button
                        variant="warning"
                        class="ml-1 mobile-margin-bottom"
                        :to="{name:'add-qa'}"
                        v-if="checkPermission('qm-qi', 'Add') && tab_status != 'completed'"
                      >
                        <span class="text-nowrap">Add</span>
                      </b-button>

                      

                  </div>
              </b-col>

          </b-row>
      </div>

      <b-table sticky-header
          ref="refUserListTable"
          class="position-relative sTable reverse-padding"
          :items="dataList"
          responsive
          :fields="tab_status == 'draft' ? tableColumns1 : tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
      >

          <template #head(checkbox)="items">
              <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall" />
          </template>

          <template #cell(checkbox)="items">
              <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />
          </template>

          <template #cell(info)="items">             
            <feather-icon
              :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
              @click="items.toggleDetails"
            />
          </template>

          <template #cell(unique_id)="items">
              <span class="wordBreak" @click="items.toggleDetails">{{ items.item.unique_id}}</span>
          </template>

          <template #cell(total_score)="items">
              <span class="wordBreak">{{ items.item.total_score +' %' }}</span>
          </template>

          <template #cell(location)="items">
              <span class="wordBreak">{{ items.item.location.name | capitalize}}</span>
          </template>

          <template #cell(inspection_date_time)="items">
              <span @click="items.toggleDetails">{{sitedateTime2(items.item.inspection_date_time,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
          </template>

          <template #cell(status)="items">    
              <div @click="items.toggleDetails">
                  <div v-if="items.item.status == 'completed'">
                
                    <b-badge pill variant="danger" class="text-capitalize" v-if="(items.item.reject_to != null)" >
                        <span>
                            {{ 'Rejected - '+ items.item.reject_by | removeUnderCapitalize}}
                        </span>
                    </b-badge>

                    <b-badge pill variant="warning" class="text-capitalize" v-else-if="(items.item.level_one_user_signature == null && items.item.level_user_status != null)">
                        <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                            {{ 'Pending' | removeUnderCapitalize}}
                        </span>
                        <span v-else>
                            {{ 'Pending - '+ items.item.level_one_user_role | removeUnderCapitalize}}
                        </span>
                    </b-badge>
                    
                    <b-badge pill variant="warning" class="text-capitalize" v-else-if="(items.item.level_user_status != null) && (items.item.level_one_user_signature != null) && (items.item.level_two_user_signature == null)" >
                        <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                            {{ 'Pending' | removeUnderCapitalize}}
                        </span>
                        <span v-else>
                            {{ 'Pending - '+ items.item.level_two_user_role | removeUnderCapitalize}}
                        </span>
                    </b-badge>

                    <b-badge pill variant="warning" class="text-capitalize" v-else-if="(items.item.level_user_status != null) && (items.item.level_two_user_signature != null)" >
                        <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                            {{ 'Pending' | removeUnderCapitalize}}
                        </span>
                        <span v-else>
                            {{ 'Pending - '+ items.item.level_three_user_role | removeUnderCapitalize}}
                        </span>
                    </b-badge>

                    <b-badge pill variant="success" class="text-capitalize" v-else >
                    {{ 'Completed'}}
                    </b-badge>
                  </div>
                  <div v-else>

                    <b-badge pill variant="secondary" class="text-capitalize" >
                      {{ 'Draft' }}
                    </b-badge>

                  </div>
              </div>
          </template>

          <template #cell(actions)="items">
              
              <!-- v-if="((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'admin') ))"              -->
              <b-link
                  v-b-tooltip.hover.v-warning
                  title="Delete"
                  variant="outline-warning"
                  v-if="checkPermission('qm-qi', 'Delete') && (((items.item.status == 'completed') && (items.item.level_user_status != null)) || items.item.status == 'draft')"
                  @click="deleteItem(items.item._id)"
              >
                  <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
              </b-link>

              <b-link
                  v-b-tooltip.hover.v-warning
                  title="Download"
                  variant="outline-warning"
                  @click="download(items.item.pdf)"
                  v-if="(items.item.status == 'completed') && (items.item.pdf != null) && checkPermission('qm-qi', 'Download Report')"
              >
                  <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
              </b-link>

              <b-link
                  v-b-tooltip.hover.v-warning
                  title="Regenerate PDF"
                  variant="outline-warning"
                  @click="reGenerateReport(items.item._id)"
                  v-if="(items.item.status == 'completed') && (items.item.level_user_status == null) && checkPermission('qm-qi', 'Re Generate Report')"
              >
                <feather-icon icon="RefreshCwIcon" class="mediumSize mr-1" />
              </b-link>

              <!-- v-if="(((items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'admin')) && ($store.getters.currentUser.role != 'site_manager') && ($store.getters.currentUser.role != 'site_client')) || ((items.item.reject_to == $store.getters.currentUser.role) && ($store.getters.currentUser.role != 'site_manager') && ($store.getters.currentUser.role != 'site_client')))" -->
              <b-link
                  v-b-tooltip.hover.v-warning
                  title="Edit"
                  variant="outline-warning"
                  :to="{name:'add-qa',params:{id:items.item._id}}"
                  v-if="checkPermission('qm-qi', 'Edit') && ((items.item.status == 'draft' ||  (items.item.status == 'completed' && items.item.level_user_status != null)) && ($store.getters.currentUser.role == 'admin' || checkEditUser(items.item)))"
              >
                  <feather-icon icon="EditIcon" class="mr-1 mediumSize"/>
              </b-link>
              <b-link
                  v-b-tooltip.hover.v-warning
                  title="Edit"
                  variant="outline-warning"
                  :to="{name:'edit-qa',params:{id:items.item._id}}"
                  v-if="checkPermission('qm-qi', 'Edit') && ($store.getters.currentUser.role == 'admin' && (items.item.status == 'completed' && items.item.level_user_status == null))"
              >
                  <feather-icon icon="EditIcon" class="mr-1 mediumSize"/>
              </b-link>

              
              <b-link
                  v-b-tooltip.hover.v-warning
                  title="Sign"
                  variant="outline-warning"
                  @click="openSignature(items.item._id,items.item.checked_date)"
                  v-if="checkPermission('qm-qi', 'Update') && (((items.item.status == 'completed') && (items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'admin')) && items.item.reject_comment == null))"
              >
                  <feather-icon icon="CheckCircleIcon" class="mr-1 mediumSize"/>
              </b-link>

              
              <b-link
                  v-b-tooltip.hover.v-warning
                  title="Reject"
                  variant="outline-warning"
                  @click="openRejectBox(items.item)"
                   v-if="checkPermission('qm-qi', 'Update') && (((items.item.status == 'completed') && (items.item.level_user_status != null) && ((items.item.level_user_status.role == $store.getters.currentUser.role) || ($store.getters.currentUser.role == 'admin')) && allowReject(items.item)))"
              >
                  <feather-icon icon="SlashIcon" class="mr-1 mediumSize"/>
              </b-link>
          
          </template>

           <template #row-details="items">
            <div class="p-1 px-2 fbDetail mb-1">
                <b-row>

                  <b-col cols="12" xl="12" >
                      <table class="mt-2 mt-xl-0 w-100">
                          <tr class="mb-1">
                            

                              <th class="pb-50" style="width: 16%">
                                <div class="d-flex align-items-center">
                              
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Project Site 
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                  {{ items.item.site.site_name | capitalize }}
                              </td>
                              <th class="pb-50" style="width: 16%">
                                <div class="d-flex align-items-center">
                            
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Inspected By 
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                  {{ items.item.inspected_by | capitalize }}
                              </td>

                              <th class="pb-50" style="width: 16%">
                                <div class="d-flex align-items-center">
                            
                                  <div class="ml-1">
                                    <h6 class="mb-0 mediumFonts">
                                      Approver Remarks 
                                    </h6>
                                  <!--  <small>Monthly Sales</small> -->
                                  </div>
                                </div>
                              </th>
                              <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                <span v-b-tooltip.hover.v-primary.top :title="items.item.level_one_user.role + ' - ' + items.item.level_one_user_remarks | removeUnderCapitalize"
                                  class="wordBreak" v-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length > 50">
                                  {{ items.item.level_one_user.role | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
                                <span class="wordBreak"
                                    v-else-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length <= 50">{{ items.item.level_one_user.role
                                      | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
                                <span class="wordBreak" v-else>{{ 'N/A' }}</span>
                              </td>
                            </tr>



                              <tr class="mb-1">
                                  <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Reject Reason 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                  </th>
                                  <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span v-if="items.item.reject_comment && items.item.reject_comment.length > 50" 
                                        v-b-tooltip.hover.v-primary.top :title="items.item.reject_comment" class="wordBreak">
                                        {{ items.item.reject_comment | capitalize | truncate }}</span>
                                      <span v-else class="wordBreak">{{ items.item.reject_comment | capitalize }}</span>
                                  </td>
                            </tr>
                        
                      </table>
                  </b-col>

                </b-row>
            </div>

        </template>



      </b-table>

      <div class="mx-2 mb-2">
          <b-row>

              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" >
                  <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
              </b-col>
          
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecords"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @input="filterTable"
                  >
                      <template #prev-text>
                          <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                          />
                      </template>
                      <template #next-text>
                          <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                          />
                      </template>
                  </b-pagination>
              </b-col>

          </b-row>
      </div>
      
      
      <!-- table ends -->

      <b-modal
          id="signatureModel"
          ref="signatureModel"
          centered
          title="Signature"
          no-close-on-backdrop
          hide-footer
          @hide="hideSignature"
          @show="hideSignature"
        >
        
        <div v-if="show_default == true">
          <b-row>

            <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
              <center>
                <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
              </center>

            </b-col>
          </b-row>

          <b-row class="mt-2">
        
            <b-col md="12">
              <date-picker v-model="approve_signature_time" 
                format="DD MMM YYYY HH:mm" 
                type="datetime"
                placeholder="Select"
                valueType="format"
                :clearable=false
                :confirm=true
                lang="en"
                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
                :disabled-date="disabledDate2"
                :disabled-time="disabledTime2"
                ></date-picker>


            </b-col>
          </b-row>
          <b-row class="mt-2">
        
            <b-col md="12">
              <b-form-group
                  label="Remarks"
                  class=""
                >
                  <b-form-textarea
                    placeholder=""
                    rows="3"
                    v-model="approve_remark"
                  />
                </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">

            <b-col md="6">

                <b-button
                    variant="secondary"
                    class="mt-0 w-100"
                    @click="show_default = false"
                  >
                  <span class="text-nowrap">Add New Signature</span>
                </b-button>
              </b-col>

              <b-col md="6">
                <b-button
                    variant="warning"
                    class="mt-0 float-right w-100"
                    @click="saveDefault()"
                  >
                  <span class="text-nowrap">Submit</span>
                </b-button>
              </b-col>
          </b-row>
        </div>

        <div v-else>
          <b-row>
            <b-col md="12">
              <VueSignaturePad
                id="signature"
                width="100%"
                height="300px"
                ref="signaturePad"
                :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
              />

            </b-col>
          </b-row>
          <b-row class="mt-2">
        
            <b-col md="12">
              <date-picker v-model="approve_signature_time" 
                format="DD MMM YYYY HH:mm" 
                type="datetime"
                placeholder="Select"
                valueType="format"
                :clearable=false
                :confirm=true
                lang="en"
                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
                :disabled-date="disabledDate2"
                :disabled-time="disabledTime2"
                ></date-picker>


            </b-col>
          </b-row>
          <b-row class="mt-2">
        
            <b-col md="12">
              <b-form-group
                  label="Remarks"
                  class=""
                >
                  <b-form-textarea
                    placeholder=""
                    rows="3"
                    v-model="approve_remark"
                  />
                </b-form-group>
            </b-col>
          </b-row>

          

          <b-row class="mt-2">

            <b-col md="4" v-if="having_default == true">

                <b-button
                    variant="secondary"
                    class="mt-0 w-100"
                    @click="show_default = true"
                  >
                  <span class="text-nowrap">Default</span>
                </b-button>
              </b-col>


            <b-col :md="having_default == true ? '4' : '6'">

                <b-button
                    variant="danger"
                    class="mt-0 w-100"
                    @click="clearSignature"
                  >
                  <span class="text-nowrap">Clear</span>
                </b-button>
              </b-col>

              <b-col md="4" :md="having_default == true ? '4' : '6'">
                <b-button
                    variant="warning"
                    class="mt-0 w-100"
                    @click="saveSignature"
                  >
                  <span class="text-nowrap">Submit</span>
                </b-button>
              </b-col>
          </b-row>
        </div>
      </b-modal>

      <b-modal
        id="rejectbox"
        ref="rejectbox"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Reject Report"
        no-close-on-backdrop
        @ok="markReject"
        >

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Reason"
                class="required"
              >
                <b-form-textarea
                  placeholder="3 to 500 characters"
                  rows="3"
                  v-model="comment"
                />
              </b-form-group>
            </b-col> 
          </b-row>
        </b-form>

      </b-modal>
  </div>
</template>

<script>
import {
BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
BPagination,VBTooltip,BCardBody,BFormCheckbox,BFormDatepicker,BBreadcrumb, BTabs, BTab, BFormGroup, BFormSelect,BFormSelectOption,
BFormTextarea, BAlert, BForm
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import ReportComponent from './ReportComponent.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Bus from "../../../event-bus";

export default {
components: {
  DatePicker,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardBody,
  vSelect,
  BFormDatepicker,
  BBreadcrumb,
  BTabs, BTab,
  ReportComponent,
  BFormGroup,
  BFormTextarea,
  BAlert, BForm, BFormSelect,BFormSelectOption
},
directives: {
  'b-tooltip': VBTooltip,
  Ripple,
},
props: ['siteData', 'locations', 'start', 'end', , 'report_status', 'tab_status'],
  watch: { 
      siteData: function(newVal, oldVal) { // watch it
        
          this.filterTable();
      },
      locations: function(newVal, oldVal) { // watch it
          this.filterTable();
      },
      start: function(newVal, oldVal) { // watch it
          
          this.filterTable();
      },
      end: function(newVal, oldVal) { // watch it
          
          this.filterTable();
      },
      report_status: function(newVal, oldVal) { // watch it
          this.filterTable();
      },
      tab_status: function(newVal, oldVal) { // watch it
          
        this.filterTable();
      },
  },
filters: {
    moment: function(date) {
        return moment(date).format('DD MMM YYYY')
    },
  },
data() {
  return {
      mode: 'single',
      items:[],
      isSortDirDesc: true,
      perPageOptions: [10, 20, 50, 100],
      perPage : 10,
      sortBy : 'order',
      webUrl: process.env.VUE_APP_SERVER_URL,
      totalRecords : 0,
      currentPage : 1,
      searchQuery:'',

      from:null,
      to:null,
      selectAll:false,
      selected:[],

      show_default:false,
      having_default:false,
      approve_signature_time:null,
      approve_remark:'',
      checked_date:null, 
      comment:null,   

      error_message: null, 
      showDismissibleAlert:false,

      tableColumns1: [

        { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%', fontSize:'11px'} },
        { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '2%', fontSize:'11px' } },
        { key: 'unique_id', label: 'ID', sortable: true, thStyle: { width: '20%', fontSize:'11px' } },
        { key: 'location', label: 'Location', sortable: false, thStyle: { width: '19%', fontSize:'11px' } },
        // { key: 'total_score', label: 'Total Score', sortable: false, thStyle: { width: '10%', fontSize:'11px' } },
        { key: 'inspection_date_time', label: 'Inspection Date & Time', sortable: true, thStyle: { width: '15%', fontSize:'11px' } },
        { key: 'status', label: 'Status', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '18%', fontSize:'11px' } },
        { key: 'actions', label: 'Action', sortable: false, thStyle: { width: '26%', fontSize:'11px' } },
      ],

      tableColumns: [
        { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%', fontSize:'11px' } },
        { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '2%', fontSize:'11px' } },
        { key: 'unique_id', label: 'ID', sortable: true, thStyle: { width: '20%', fontSize:'11px' } },
        { key: 'location', label: 'Location', sortable: false, thStyle: { width: '19%', fontSize:'11px' } },
        // { key: 'total_score', label: 'Total Score', sortable: false, thStyle: { width: '10%', fontSize:'11px' } },
        { key: 'inspection_date_time', label: 'Inspection Date & Time', sortable: true, thStyle: { width: '15%', fontSize:'11px' } },
        { key: 'status', label: 'Status', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '18%', fontSize:'11px' } },
        { key: 'actions', label: 'Action', sortable: false, thStyle: { width: '26%', fontSize:'11px' } },
      ],

        supervisorSign : {
            image:null,
            name:'',
            type: 'supervisor',
            default:'no'
        },

        
  }
},
methods : {
  reGenerateReport(id){
      return this.$store.dispatch(POST_API, {
          data:{ id:id },
          api: '/api/regenerate-qm-qa'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.popshowDismissibleAlert = true;
          } else {
              this.popshowDismissibleAlert = false;
              
              var data  = this.$store.getters.getResults.data;
              this.filterTable();
              this.successAlert().then( () => {
                  window.open(data + '?' + new Date(), '_blank');
                  this.filterTable();  
              })
          }
      });
  },
  
  closeQI(){
    this.decisionAlert('Are you sure you want to close selected record?').then(result => {
    if (result.value) {
        if (this.selected.length > 0) {			
          if (this.$store.getters.currentUser.default_signature == null) {
            this.openSignatureBox();
          }else {
            var data = {
              image:this.$store.getters.currentUser.default_signature,
              name:'',
              type:'client',
              default:'yes'
            }
            this.markClosedTask(data);
          }
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Please Select QI.',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
    })
  },

  openSignatureBox(){
    // this.$refs['signatureModel'].show();
    if (this.having_default == true) {
      this.show_default = true;
    }else{
      this.show_default = false;
    }
    this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
    this.approve_remark = '';
    this.mode = 'multiple';
    this.$refs['signatureModel'].show();
  },

  markClosedTask(data){
    return this.$store.dispatch(POST_API, {
      data:{
        signature:data,
        ids:this.selected,
        role:this.$store.getters.currentUser.role,
        approve_signature_time:this.approve_signature_time,
        approve_remark:this.approve_remark,
        from:'web'
      },
      api: '/api/close-multiple-qm-qa'
    })
    .then(() => {
      if (this.$store.getters.containsErrors) {
        this.error_message = this.$store.getters.getErrors;
        this.errorAlert();              
      } else {
        this.popshowDismissibleAlert = false;
        this.$emit('updateStatus', 'completed');
        this.successAlert();

        this.selected = [];
        this.selectAll = false;
      }
    });
  },

  disabledDate2(date){
    var today = moment().tz('Asia/Singapore');
    return date >= today;
  },
  disabledTime2(date, currentValue){
    return date >= moment().tz('Asia/Singapore');
  },

  getReport(type){
    if(!this.start || this.start == 'Invalid date'){
      this.customAlert('Select Date', 'Please select date to Download Report.', 'warning')
    } else {
      var keyword = this.searchQuery;
      var sortBy = this.sortBy;
      var sortDirection = this.isSortDirDesc;
      var site = this.siteData ? this.siteData._id : "";
      var site_name = this.siteData ? this.siteData.site_name : "";
      
      var locations  =  [];

      for (var i = 0; i < this.locations.length; i++) {
          locations.push(this.locations[i]._id);
      }

      var om_sites = [];

      for (var i = 0; i < this.$store.getters.currentUser.om_sites.length; i++) {
          om_sites.push(this.$store.getters.currentUser.om_sites[i]);
      }

      var role = this.$store.getters.currentUser.role;

      var start = this.start ? moment(this.start).format('YYYY-MM-DD') : "";
      var end = this.end ? moment(this.end).format('YYYY-MM-DD') : "";
      var status = this.status;

      var params = "keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&site_name="+site_name+
          "&locations="+locations+"&start="+start+"&end="+end+"&organization="+ this.$store.getters.currentUser.organization+
          "&role="+role+"&om_sites="+om_sites.join(',')+"&status="+status;

      var baseurl = "";

      if (type == 'pdf') {
          baseurl = process.env.VUE_APP_SERVER_URL +"/api/qm-qa-report-pdf?"+params;
      }else{
          baseurl = process.env.VUE_APP_SERVER_URL +"/api/qm-qa-report-excel?"+params;
      }
      window.open(baseurl,'_blank');
    }
  },

  changeMultipleStatus(status){
      if (this.selected.length > 0) {
          var msg = '';
          var msg2 = '';

          if (status == 'deleted') {
              msg = 'Are you sure you want to delete selected record?';
              msg2 = 'Records Deleted Successfully.';
          }

          this.decisionAlert(msg)
          .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                      data:{
                          id: this.selected,
                          status:status
                      },
                      api:"/api/qm-qa-multiple-delete",
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                      } else {

                          this.successAlert();

                          this.selected = [];
                          this.selectAll = false;
                          this.filterTable();
                      }
                  });
              }
          }).catch(err => {})
      } else {
          Swal.fire({
              icon: 'warning',
              title: 'Please select tasks.',
              showConfirmButton: false,
              timer: 1500
          })
      }
  },
  approveReport(sign){

      return this.$store.dispatch(POST_API, {
          data:{
              crew_signature:sign,
              id:this.id,
              role:this.$store.getters.currentUser.role,
              approve_signature_time:this.approve_signature_time,
              approve_remark:this.approve_remark,
          },
          api: '/api/approve-qm-qa-report'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              /*this.showDismissibleAlert = true;
              window.scrollTo(0,0);*/
              this.errorAlertCF();
              //this.$refs.refUserListTable.refresh();
          } else {
              this.showDismissibleAlert = false;
              var data  = this.$store.getters.getResults.data;
              
              this.successAlert();
              this.$refs.refUserListTable.refresh();

              // Bus.$emit('counter_update');
              window.open(data,'_blank');
          }
      });
  },

  disabledDate(date){
      return date < moment(new Date(this.checked_date)).subtract(1,'days').tz('Asia/Singapore');
  },
  openRejectBox(item){
      this.showDismissibleAlert = false;
      this.id = item._id;
      this.comment = item.reject_comment; 
      this.$refs['rejectbox'].show();
    },
    markReject(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
         data:{
           comment:this.comment,
           id:this.id,
           role:this.$store.getters.currentUser.role
         },
         api: '/api/reject-qm-qa-report'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
          } else {
              this.showDismissibleAlert = false;
              var message  = this.$store.getters.getResults.message;
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: message,
                showConfirmButton: false,
                timer: 1500
              }).then((result) => {
                  this.filterTable();
                  Bus.$emit('counter_update');
                  this.$refs['rejectbox'].hide();
              });

              
          }
      });
    },
  allowReject(item){
      var role = this.$store.getters.currentUser.role;

      if (item.reject_to == role && ((item.level_one_user_role != role) && (item.level_two_user_role != role) && (item.level_three_user_role != role))) {          
        return false;        
      } else if (item.reject_by == role){
        return false;
      } else if (((item.reject_behalf == 'admin')) && ((role == 'admin'))){
        return false;
      } else if ((item.level_user_status != null) && (item.level_user_status.role == '')){
        return false;
      } else {
        return true;
      }
    },
  dataList(){
    return this.$store.dispatch(POST_API, {
                 data:{
                   page: this.currentPage,
                   keyword: this.searchQuery,
                   rowsPerPage:this.perPage,
                   sortBy:this.sortBy,
                   sortDirection:this.isSortDirDesc,
                   role:this.$store.getters.currentUser.role,
                   om_sites:this.$store.getters.currentUser.om_sites,
                   site:this.siteData ? this.siteData._id :null,
                   locations : this.locations,
                   start:moment(this.start).format('YYYY-MM-DD'),
                   end:moment(this.end).format('YYYY-MM-DD'),
                   tab_status    : this.tab_status,
                 },
                 api: '/api/qm-qa-report-list'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                  } else {
                      this.items = this.$store.getters.getResults.data.docs;
                      this.totalRecords = this.$store.getters.getResults.data.total;
                      this.perPage = this.$store.getters.getResults.data.limit;
                      this.from = this.$store.getters.getResults.data.from
                      this.to = this.$store.getters.getResults.data.to

                      var data = {
                        draft: this.$store.getters.getResults.data.draft,
                        validate: this.$store.getters.getResults.data.validate,
                        completed: 0,
                        all: 0
                      }

                      this.$emit('updateCount', data);



                      return this.items;
                  }
              });
  },
  filterTable(){
    this.$refs.refUserListTable.refresh();
  },
  variantColor(status){
    if (status == 'active') {
      return 'success';
    }else{
      return 'primary';
    }
  },
  selectall(e){

      this.selected = [];

      if (e) {
      var selected = [];

      this.items.forEach(function(item){
          // if(item.status != 'draft'){
            selected.push(item._id);
          // }
      })

      this.selected = selected;

      }
  },
  zipDownload(){
      var organization  = this.$store.getters.currentUser.organization;
      var id            = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
      var start         = moment(this.start).format('YYYY-MM-DD');
      var end           = moment(this.end).format('YYYY-MM-DD');
      var site          = this.siteData ? this.siteData.site_name : '';
      
      var params  = "organization="+organization+"&id="+id+"&start="+start+"&end="+end+"&site="+site;
      var baseurl = "";

      baseurl        = process.env.VUE_APP_SERVER_URL + '/api/qm-qa-zip-report?' + params;
      this.selectAll = false,
      this.selected  = []
      
      window.open(baseurl,'_blank');
  },

  deleteItem(id){
    var msg = 'Are you sure want to delete this record?';
    var message='Report Deleted Successfully.';

    Swal.fire({
        title: 'Please Confirm',
        text: msg,
        icon: 'warning',
        position: 'top-center',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    })
    .then(result => {
      if (result.value) {
          return this.$store.dispatch(POST_API, {
            data:{
                  id: id,
                  status:'deleted'
                },
            api:"/api/change-qm-qa-report-status",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showAlert();
              } else {
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: message,
                  showConfirmButton: false,
                  timer: 1500
                })
                
                this.$refs.refUserListTable.refresh();
              }
          });
      }
    })
    .catch(err => { })
  },

  download(pdf){
    window.open(pdf, '_blank');
  },

  openSignature(id,date){        
        if (this.having_default == true) {
            this.show_default = true;
        }else{
            this.show_default = false;
        }
        this.checked_date = date;
        this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
        this.approve_remark = '';
        this.id = id; 
        this.mode = 'single';
        this.$refs['signatureModel'].show();
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'signature.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    saveDefault(){
        this.supervisorSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
        this.approveReport(this.supervisorSign);
        this.$refs['signatureModel'].hide();
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
        this.$refs.signaturePad.clearSignature();
    },

    saveSignature(){
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (isEmpty) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Signature is required',
                showConfirmButton: false,
                timer: 1500
            })
        }else{
            this.supervisorSign.image = data;
            this.supervisorSign.name = 'signature.png';
            this.supervisorSign.type =  'supervisor';
            this.supervisorSign.default = 'no';

            if(this.mode == 'multiple'){
              this.markClosedTask(this.supervisorSign);
            } else {
              this.approveReport(this.supervisorSign);
            }
            this.$refs['signatureModel'].hide();
        }
    },
    checkEditUser(item){
      var role = this.$store.getters.currentUser.role;

      if((role != item.level_one_user_role && role != item.level_two_user_role && role != item.level_three_user_role) && item.validators.indexOf(role) >=0){
        return true
      }else {
        return false;
      }
    }
},
mounted() {
  this.useDefault();
  
}

}
</script>